<template>
    <div class="eCommerce-container">
        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column prop="topic" label="数据分析内容"></el-table-column>
            <el-table-column label="内容">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.answer" :ref="`answer_${scope.$index}`" style="width: 320px"></el-input>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>
        <div class="bottom-btn">
            <el-button type="primary" @click="submitBtn">提交</el-button>
        </div>
    </div>
</template>

<script>
    import { baseDataAnalysisStuDataAnalysis, baseDataAnalysisStuDataAnalysisSave } from '@/utils/apis.js'
    export default {
        data() {
            return {
                op_id: Number(this.$route.query.op_id) || null,
                course_id: Number(this.$route.query.course_id) || null,
                infoList: [],
                isExamOrTrain: localStorage.getItem('examId'), // 有考试， 没有训练
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList() {
                let params = {}
                if (!this.isExamOrTrain) {
                    params.op_id = this.op_id
                    params.course_id = this.course_id
                }
                baseDataAnalysisStuDataAnalysis(params).then((res) => {
                    this.infoList = res.data
                }).catch((err) => {})
            },
            // 提交
            submitBtn() {
                this.infoList.forEach((item, index) => {
                    if (!item.answer) {
                        this.$refs[`answer_${index}`].$el.classList.add('isError')
                    } else {
                        this.$refs[`answer_${index}`].$el.classList.remove('isError')
                    }
                })
                for (let i = 0; i < this.infoList.length; i++) {
                    let item = this.infoList[i]
                    if (!item.answer) {
                        return this.$message.warning('请填写答案')
                    }
                }

                let params = {
                    data_analysis_center: this.infoList
                }
                if (!this.isExamOrTrain) {
                    params.op_id = this.op_id
                    params.course_id = this.course_id
                }
                baseDataAnalysisStuDataAnalysisSave(params).then((res) => {
                    this.$message.success(res.msg)
                    this.getList()
                }).catch((err) => {})
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .bottom-btn {
        text-align: center;
        margin: 40px 0;
    }
</style>